// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    text-align: center;
    margin-top: 50px;
  }
  
  .buttons {
    margin-bottom: 20px;
  }
  
  .data {
    border: 1px solid #ccc;
    padding: 10px;
    max-width: 500px;
    margin: 0 auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":[".container {\n    text-align: center;\n    margin-top: 50px;\n  }\n  \n  .buttons {\n    margin-bottom: 20px;\n  }\n  \n  .data {\n    border: 1px solid #ccc;\n    padding: 10px;\n    max-width: 500px;\n    margin: 0 auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
